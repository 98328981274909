<template>
  <div class="pbf grid_width">
 
    <div class="followingList">


      <SkBasicRows 
        v-if="is_loading && !is_ended && !following_data?.items_follower?.length"
        />

      <template
        v-else-if="following_data?.items_follower?.length" 
        v-for="pro in following_data?.items_follower">
        <GrowerFollowItem
          :data="pro"
          :follows="following_data?.addon?.follows"
          />
      </template>

      <UiEmpty 
        v-else
        :text="'Nothing was found'"
        />
               
      <client-only>        
        <InfinityScroll 
          v-if="!is_ended"
          :is-loading="is_loading"
          ref="el_infinity_scroll"/>
      </client-only>

    </div>
  

  </div>
</template>

<script setup>

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const {$api, $adv, $tagsUtil, $ga} = useNuxtApp()
const el_infinity_scroll = ref(null)
const props = defineProps({
  inurl: {
    type: String
  },
});

var start = ref(0);
var limit = ref(20);
var is_ended = ref(false)
var list = ref([]);

const clearPage = function(){   
  start = 0;
  list = [];
}

const infiniteHandler = function(state){
  start += limit;
  loadResults(state);
}


const loadData = async function() {  
  const response = await $api.getUrl(props.inurl, {
    start: start.value,
    limit: limit.value,
  });
  return response;  
}



const { pending: is_loading,  data: following_data } = await useLazyAsyncData('following_data', async () => await loadData())


watchArray([start], async ([new_start], added, removed) => {


  if(new_start == 0){
    following_data.value.items_follower = [];
    is_ended.value = false;
  }

  console.log('watcher');
  is_loading.value = true;
  var dt = await loadData();
    
  if(!dt.items_follower?.length)
    is_ended.value = true;  

  following_data.value.items_follower = [...following_data.value.items_follower, ...dt.items_follower]
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})


useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

 
</script>

<style scoped>

 

.followingList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.followingList.centered {
}




</style>
